import React from 'react';

import Layout from '../../components/Layout';

import pic1 from '../../assets/images/services/web-design-services.jpg';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const WebDesign = () => (
  <Layout>
    <div id="main">
      <div className="inner box">
        <h1>Web Design Services</h1>
        <span className="image main">
          <img src={pic1} alt="" />
        </span>
        <article className="box">
        <p>
        Our fully-customized website designs produce greater brand engagement, higher conversions, and measurable results. 
        We have a geographically diverse team that specializes in developing the fascinating 
        layouts with better usability, stronger content, captivating calls-to-action that increases the client interaction. 
        Developing responsive designs with interactive graphic design is our strong suit as we strongly believe that 
        they generate higher conversions across desktop, tablet, and mobile devices. Due to the expeditious increase 
        in the mobile usage, optimization of the website for mobiles and tablets is mandatory. 
        Our adept team delivers responsive designs and ensures that your visitors have an excellent experience. 
        We step up demands for the clients with an online experience that enhances the ROI. 
        </p>
        <Row><Col>
        <Card>
          <Card.Header>WebDesign / Redesign</Card.Header>
            <Card.Body>
              <p>
              We create fully functional and exceptional designs that are both interactive and effective.
              We believe that your website represents the quality of your work and establish your brand in the market. 
              We make sure that your business empire grows online both in terms of sales and lead generation. 
              Our team works with sincerity and we understand completely what it takes to develop websites with 
              creativity and innovative techniques so as to convert the potential clients into customers by following proper marketing techniques.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Website maintenance</Card.Header>
            <Card.Body>
              <p>
                We are a full service digital agency specializing in website design and continuing maintenance. 
                Since web technologies are changing at a rapid pace, we keep up with the changes, so that you get the best in class service.
                Whether you want to update a theme , add a functionality or need to replace the content, we are here to help!
                Our professionals are available 24 /7 to meet your website maintenance needs.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>CMS Development</Card.Header>
            <Card.Body>
              <p>
              Content management system (CMS) is one of the backend tools that permit an organizer 
              to control his website making him independent to change content like add or remove photos, 
              text and videos. It support an organizer in keeping his website spanking new so that 
              the patron and SE are engrossed as the site is maintained up-to-date with latest trend and interest.
              A website being mirror of one's business, CMS acts as the silver foil of its backend, which enhances and gives best reflection of business.
              </p>
              We offer various types of Content Management Solutions such as:
              <ul>
                <li>Web Content Management Solutions</li>
                <li>Document Management Solutions</li>
                <li>Knowledge Management Solutions</li>
                <li>Media and digital Asset Management</li>
              </ul>
            </Card.Body>
          </Card>
          </Col></Row>
        </article>
      </div>
    </div>
  </Layout>
);

export default WebDesign;